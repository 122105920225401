<template>
    <b-container fluid>
        <iq-card>
             <template v-slot:body>
                <b-row>
                    <b-col class="col-lg-6">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('org_pro_division.division')"
                        label-for="division_id"
                        >
                        <b-form-select
                            plain
                            v-model="search.division_id"
                            :options="divisionList"
                            id="division_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col  class="col-lg-6">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('org_pro_district.district')"
                        label-for="district_id"
                        >
                        <v-select name="godown_Info"
                            v-model="search.district_name"
                            label="text"
                            :options= districtList
                            id="district_id"
                            @input="getGodownInfoDistrictWise()"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-lg-6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('fertilizerConfig.godown_information.godown_information')"
                            label-for="id"
                        >
                            <v-select name="godown_Info"
                                v-model="search.godown_Info_name"
                                label="text"
                                :options= godownInfoList
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-sm-2 col-md-12">
                        <b-button class="float-right" type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('fertilizerConfig.godown_information.godown_information_list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" variant="primary" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i> {{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table class="table-striped" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <div class="status_wrapper status_active" v-if="data.item.status == 1" title="active">
                                                <b-badge variant="success">Active</b-badge>
                                            </div>
                                            <div class="status_wrapper status_inactive" v-else title="inactive">
                                                <b-badge variant="dark">Inactive</b-badge>
                                            </div>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Change Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                                <i class="fa fa-toggle-off"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { godownInformationList, godownInformationToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                godown_Info_name: null,
                id: 0,
                sale_center_name: null,
                sales_center_id: 0,
                division_id: 0,
                district_id: 0
            },
            districtList: [],
            godownInfoList: this.$store.state.SeedsFertilizer.commonObj.godownInfoList,
            upazilaList: []
        }
    },
    computed: {
        formTitle () {
            return (this.editItemId === 0) ? this.$t('fertilizerConfig.godown_information.godown_information') + ' ' + this.$t('globalTrans.entry') : this.$t('fertilizerConfig.godown_information.godown_information') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('fertilizerConfig.godown_information.warehouse_name'), class: 'text-center' },
                { label: this.$t('globalTrans.region'), class: 'text-center' },
                { label: this.$t('globalTrans.division'), class: 'text-center' },
                { label: this.$t('globalTrans.district'), class: 'text-center' },
                { label: this.$t('globalTrans.upazila'), class: 'text-center' },
                { label: this.$t('fertilizerConfig.sales_center.sales_center'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'name_bn' },
                { key: 'region_name_bn' },
                { key: 'division_name_bn' },
                { key: 'district_name_bn' },
                { key: 'upazilla_name_bn' },
                { key: 'sales_center_name_bn' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'name' },
                { key: 'region_name' },
                { key: 'division_name' },
                { key: 'district_name' },
                { key: 'upazilla_name' },
                { key: 'sales_center_name' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        divisionList: function () {
            return this.$store.state.commonObj.divisionList
        },
        SalesCenterList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.salesCenterList
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
        'search.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
            this.godownInfoList = this.getGodownInfoList(newVal)
        }
    },
    methods: {
        getGodownInfoDistrictWise () {
            this.godownInfoList = this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(godownInfo => godownInfo.district_id === this.search.district_name.value)
        },
        getGodownInfoList (divisionId = null) {
            const godownInfo = this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(godownInfo => godownInfo.division_id === divisionId)
            return godownInfo
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
                if (divisionId) {
                    return districtList.filter(district => district.division_id === divisionId)
                }
            return districtList
        },
        remove (item) {
            this.changeStatus(seedFertilizerServiceBaseUrl, godownInformationToggleStatus, item, 'seeds_fertilizer', 'godownInfoList')
        },
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                commodity_group_name: '',
                commodity_group_name_bn: '',
                editable: false
            }
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            this.search.id = null
            this.search.sales_center_id = null
            this.search.district_id = null
            if (this.search.godown_Info_name) {
                this.search.id = this.search.godown_Info_name.value
            }
            if (this.search.sale_center_name) {
                this.search.sales_center_id = this.search.sale_center_name.value
            }
            if (this.search.district_name) {
                this.search.district_id = this.search.district_name.value
            }
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, godownInformationList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getRelationalData(response.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationalData (data) {
            const divisionList = this.$store.state.commonObj.divisionList
            const districtList = this.$store.state.commonObj.districtList
            const upazilaList = this.$store.state.commonObj.upazilaList
            const salesCenterList =this.$store.state.commonObj.officeList
            const regionList = this.$store.state.commonObj.officeList
            return data.data.map(item => {
                const divisionObject = divisionList.find(division => division.value === item.division_id)
                const districtObject = districtList.find(district => district.value === item.district_id)
                const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
                const salesCenterObject = salesCenterList.find(salesCenter => salesCenter.value === item.sales_center_id)
                const regionObject = regionList.find(region => region.value === item.region_id)
                const divisionData = {
                    division_name: divisionObject !== undefined ? divisionObject.text_en : '',
                    division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
                }
                const districtData = {
                    district_name: districtObject !== undefined ? districtObject.text_en : '',
                    district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
                }
                const upazillaData = {
                    upazilla_name: upazilaObject !== undefined ? upazilaObject.text_en : '',
                    upazilla_name_bn: upazilaObject !== undefined ? upazilaObject.text_bn : ''
                }
                const salesCenterData = {
                    sales_center_name: salesCenterObject !== undefined ? salesCenterObject.text_en : '',
                    sales_center_name_bn: salesCenterObject !== undefined ? salesCenterObject.text_bn : ''
                }

                 const regionData = {
                    region_name: regionObject !== undefined ? regionObject.text_en : '',
                    region_name_bn: regionObject !== undefined ? regionObject.text_bn : ''
                }
                if(item.ports.length>0) {
                    item.ports.forEach((element4, key4) => {
                        let fertilizerZoneData = {}
                        fertilizerZoneData = this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.find(fertilizer => fertilizer.value === element4.port_id)
                        element4.value = element4.port_id
                        element4.text_en = fertilizerZoneData !== undefined ? fertilizerZoneData.text_en : '',
                        element4.text_bn = fertilizerZoneData !== undefined ? fertilizerZoneData.text_bn : ''
                    })
                } else {
                    item.ports = this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList
                }
                return Object.assign({}, item, divisionData, districtData, upazillaData, salesCenterData, regionData)
            })
        }
    }
}
</script>
